.DeleteUser {
	padding: 0 0 16px;
	display: flex;
	justify-content: center;

	@media (max-width: 1439.98px) and (min-width: 1024px) {
		padding: 0;
	}
}

.Button {
	background: none;
	margin: 0;
	padding: 16px;
	border: 0;
	font-size: 13px;
	display: block;
	color: var(--color-error, red);
	transition: color .2s;

	&:hover {
		color: var(--color-blue-dark, blue);
	}
}
