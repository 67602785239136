@import './src/resources';

.OneTimeCarrier {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.Lang {
	position: absolute;
	top: 32px;
	right: 32px;

	@include media-breakpoint-down(xs) {
		right: 16px;
	}

	:global(.LanguageMenu) {
		@include media-breakpoint-up(lg) {
			right: -16px;

			&:before {
				right: 19px;
			}
		}
	}
}
