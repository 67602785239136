.GetCmr {
	display: block;
	width: 100%;
	position: relative;
	background: #FFFFFF;
	box-shadow: -10px -10px 25px rgba(112, 124, 151, 0.05), 5px 10px 30px rgba(155, 155, 190, 0.15);
	border-radius: 20px;
}

.Docs {
	display: flex;
	flex-wrap: wrap;
	width: calc(100% - 40px);
	margin: 0 auto;
	border-top: 1px solid var(--border-color);
	padding: 14px 0;
}

.Button {
	padding: 20px 60px 18px 62px;
	width: 100%;
	color: var(--color-blue-dark);
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	position: relative;
	cursor: pointer;
	text-align: left;
	border: 0;
	margin: 0;
	display: block;
	background: none;

	&:before {
		content: '';
		width: 48px;
		height: 48px;
		background: url(/images/cmr-btn.svg) no-repeat;
		background-size: contain;
		position: absolute;
		top: 50%;
		margin-top: -24px;
		left: 12px;
	}

	&:after {
		content: '';
		width: 33px;
		height: 32px;
		position: absolute;
		top: 50%;
		margin-top: -16px;
		right: 25px;
		background: url(/images/cmr-more.svg) no-repeat;
		background-size: contain;
		transform: translateX(0);
		transition: transform .2s;
	}

	&:hover {
		&:after {
			transform: translateX(10px);
		}
	}

	&Date {
		line-height: 14px;
		font-size: 10px;
		color: var(--color);
	}

	&Title, &Date {
		display: block;
	}
}

.Arrow {
	display: block;
	width: 24px;
	height: 24px;
	background: #fff;
	position: absolute;
	top: 100%;
	left: 50%;
	margin: -16px 0 0 -12px;
	text-indent: -5000px;
	overflow: hidden;
	border-radius: 50%;
	padding: 0;
	border: 0;

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 12px;
		height: 12px;
		background: url(/images/arrow.svg) no-repeat;
		background-size: contain;
		margin-top: -6px;
		margin-left: -6px;
	}

	&:hover {
		&:before {
			animation: shake-down 2s linear infinite;
		}
	}

	&Active {
		transform: scale(1, -1);
	}
}

.OthersHeader {
	color: var(--color-blue-dark);
	font-size: 13px;
	padding: 10px 22px;
}
