@import './src/resources';

.OneTimeCarrierForm {
	width: 640px;
	max-width: 100%;
	margin: 0 auto;
}

.Title {
	color: $color-blue-dark;
	text-align: center;
	margin: 0 auto 64px;
	max-width: 480px;

	&:has(+:global(.Error)) {
		margin-bottom: 0;
	}
}

:global(.Error) {
	margin-left: auto;
	margin-right: auto;
	max-width: 480px;
}

.Submit {
	margin: 32px auto 0;
}

.Form {
	max-width: 420px;
	margin: 0 auto;
}
