$color: #9b9bbe;
$color-gray: #73738d;
$color-blue: #2c50ee;
$color-blue-2: #012EE8;
$color-blue-secondary: #1943ef;
$color-blue-dark: #333e63;
$color-bgr: #f8f9fd;
$color-bgr-disabled: #e1e1ea;
$color-active: #00D081;
$color-input: #f8f9fd;
$color-error: #EB2A50;
$color-red: #F94341;
$color-list: #3e4958;
$color-list-border: #F5F5FB;
$color-list-border-blank: #cdcddf;
$color-checkbox: #A8A8C6;
$color-green: #00D081;
$font-primary: 'Inter', sans-serif;
$statuses-new: #00D081;
$border-color: #DDE1E8;
$color-premium: #F2A600;

$status-new: #00D081;
$status-in_way_for_load: #BB6BD9;
$status-in_way_for_unload: #24CAE7;
$status-ready_for_load: #FF9900;
$status-ready_for_unload: #FF9900;
$status-accepted: #012EE8;
$status-loaded: #B7D24B;

$breakpoints: (
		xs: 640px,
		sm: 1024px,
		md: 1440px,
		lg: 1440px,
		xl: 1880px,
) !default;

@mixin media-breakpoint-down($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (max-width: map-get($breakpoints, $breakpoint) - .02) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}

@mixin media-breakpoint-up($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (min-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}


@function breakpoint-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $breakpoints) {
	$next: breakpoint-next($name, $breakpoints);
	@return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

@mixin media-breakpoint-only($name, $breakpoints: $breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$max: breakpoint-max($name, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}

@import './styles/_lib/vars';
@import './styles/_lib/fonts';
@import './styles/_lib/datetime';

@import './styles/base';

@import './styles/layout/general';
@import './styles/layout/list';
@import './styles/layout/error';
@import './styles/layout/option';
@import './styles/layout/file';
@import './styles/layout/empty';
@import './styles/layout/statuses';

.content-list {
	list-style: none;
	margin: 0;
	padding-bottom: 36px;


	li {
		padding-left: 40px;
		position: relative;
		font-size: 13px;
		color: $color-blue-dark;
		margin: 0 0 8px;

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 16px + 8px;
			width: 5px;
			height: 5px;
			background-color: $color-list;
			transform: translate(-50%, -50%);
			border-radius: 50%;
		}
	}
}

.km-tooltip {
	position: relative;

	&:before {
		text-indent: 0;
		font-size: 13px;
		content: attr(data-tooltip); /* here's the magic */
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%);
		padding: 5px 10px;
		margin-bottom: 4px;
		border-radius: 4px;
		background: $color-blue-dark;
		color: #fff;
		text-align: center;
		z-index: 2;
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 100%;
		left: 50%;
		margin-left: -4px;
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		border-top: 4px solid $color-blue-dark;
	}

	&:before, &:after {
		opacity: 0;
		pointer-events: none;
		transition: opacity .2s;
	}

	&:hover {
		z-index: 10;

		&:before, &:after {
			opacity: 1;
		}
	}
}
