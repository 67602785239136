h1 {
	font-size: 28px;
	font-weight: 800;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: $color-blue-dark;
	margin: 0 0 22px;
	line-height: 1.2;
	display: block;
	text-align: center;
}

.disabled-link {
	cursor: not-allowed;
	color: $color;
}

.form-response-error-message {
	color: $color-red;
	margin: 10px auto;
	font-size: 13px;
	text-align: center;
}

.logo {
	display: block;
	margin: 0;
	height: auto;
	max-width: 100%;
}

.hide-md {
	@include media-breakpoint-down(md) {
		display: none !important;
	}
}

.show-md {
	@include media-breakpoint-up(md) {
		display: none !important;
	}
}

.show-sm {
	@include media-breakpoint-up(sm) {
		display: none !important;
	}
}


.show-xl {
	@include media-breakpoint-up(xl) {
		display: none !important;
	}
}

.arrows-btns {
	display: flex;
	flex-wrap: wrap;
	box-shadow: -10px -10px 25px rgba(112, 124, 151, 0.05), 5px 10px 30px rgba(155, 155, 190, 0.15);
	border-radius: 8px;
	overflow: hidden;

	&--arrow {
		width: 32px;
		height: 32px;
		position: relative;
		background: #fff;
		display: block;
		text-indent: -5000px;
		transition: opacity .2s;

		&:hover {
			opacity: .6;
		}

		&:before {
			content: '';
			background: url(/images/pagination-arrow.svg) no-repeat;
			background-size: contain;
			background-position: center;
			width: 12px;
			height: 12px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -6px;
			margin-left: -6px;
		}

		&.prev {
			&:before {
				transform: scale(-1, 1);
			}
		}
	}
}

.btn-cancel {
	height: 48px;
	font-weight: 600;
	font-size: 12px;
	line-height: 136%;
	text-align: center;
	color: #333E63;
	border: 1px solid #9B9BBE;
	filter: drop-shadow(0px 2px 20px rgba(115, 115, 141, 0.1));
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.delete-button {
	width: 16px;
	height: 16px;
	overflow: hidden;
	display: block;
	text-indent: -5000px;
	position: relative;
	background: url(/images/delete.svg) no-repeat;
	background-size: contain;
	transition: opacity .2s;
	border: 0;
	margin: 0;
	padding: 0;

	&:hover {
		opacity: .7;
	}
}

.react-datepicker-popper {
	min-width: 314px;
}

.App.logged-in {
	&:before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 288px;
		height: 100%;
		background: #fff;
		box-shadow: 10px 0px 15px rgba(115, 115, 141, 0.05);

		@include media-breakpoint-down(xl) {
			width: 256px;
		}

		@include media-breakpoint-down(md) {
			width: 64px;
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	&:has(.one-time-carrier-component) {
		&:before {
			display: none;
		}
	}
}




@keyframes shake {
	0%, 16.666%, 33.333%, 100% {
		transform: translateX(0);
	}
	8.333% {
		transform: translateX(5px);
	}
	24.999% {
		transform: translateX(-5px);
	}
}



@keyframes shake-down {
	0%, 16.666%, 33.333%, 100% {
		transform: translateY(0);
	}
	8.333% {
		transform: translateY(5px);
	}
	24.999% {
		transform: translateY(-5px);
	}
}



@keyframes shake-up-with-scale {
	0%, 16.666%, 33.333%, 100% {
		transform: scale(-1,-1) translateY(0);
	}
	8.333% {
		transform: scale(-1,-1) translateY(-5px);
	}
	24.999% {
		transform: scale(-1,-1) translateY(5px);
	}
}


.loading-wrapper {
	height: 300px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}


.d-flex {
	display: flex!important;
}
