@import './src/resources';

.TransportAddSwitcher {
	min-height: 468px;

	&__copy {
		font-size: 12px;
		line-height: 15px;
		text-align: center;
		width: 264px;
		margin: 20px auto 52px;
		max-width: 100%;
	}

	&__btns {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include media-breakpoint-down(xs) {
			display: block;
		}

	}
}

.Btn {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	width: calc(50% - 16px);
	border-radius: 20px;
	border: 0;
	margin: 0;
	padding: 82px 0 24px;
	text-align: center;
	position: relative;

	@include media-breakpoint-down(xs) {
		width: 100%;
		margin: 0 0 16px;

		&:last-child {
			margin: 0;
		}
	}

	&__copy {
		display: block;
		font-weight: 800;
		font-size: 24px;
		line-height: 25px;
		padding: 0 30px;
		margin: 0 0 20px;
		width: 100%;

		@include media-breakpoint-down(xs) {
			font-size: 18px;
			line-height: 20px;
		}
	}

	&__select {
		padding: 14px 50px 14px 20px;
		border: 1px solid transparent;
		border-radius: 12px;
		display: block;
		position: relative;
		font-size: 10px;
		line-height: 12px;
	}

	&__svg {
		position: absolute;
		top: 50%;
		margin-top: -4px;
		right: 25px;
		margin-right: -6px;
		width: 12px;
		height: 8px;
		display: block;
	}

	&:hover &__svg {
		& :global {
			animation: shake 2s linear infinite;
		}
	}

	&--in {
		color: #0859FF;
		background: #E6EDFF;

		&:before {
			content: '';
			position: absolute;
			top: 20px;
			left: 50%;
			transform: translateX(-50%);
			width: 64px;
			height: 52px;
			background: url(/images/transport-in.svg) no-repeat;
			background-size: contain;
		}

		.Btn__path {
			stroke: #0859FF;
		}
	}

	&--in &__select {
		border-color: #0859FF;
	}

	&--out {
		color: #00B055;
		background: #DCF8E8;

		&:before {
			content: '';
			position: absolute;
			top: 24px;
			left: 50%;
			transform: translateX(-50%);
			width: 78px;
			height: 42px;
			background: url(/images/transport-out.svg) no-repeat;
			background-size: contain;
		}

		.Btn__path {
			stroke: #00B055;
		}
	}

	&--out &__select {
		border-color: #00B055;
	}

	&--one-time {
		color: $color-premium;
		background: rgba($color-premium, .1);
		width: 100%;
		margin-top: 32px;

		@include media-breakpoint-up(xl) {
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.Btn__copy {
				max-width: 330px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		@include media-breakpoint-down(xs) {
			margin-top: 16px;

			&:last-child {
				margin: 0;
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 24px;
			left: 50%;
			transform: translateX(-50%);
			width: 52px;
			height: 52px;
			background: url(/images/transport-one-time.svg) no-repeat;
			background-size: contain;
		}

		.Btn__path {
			stroke: $color-premium;
		}
	}

	&--one-time &__select {
		border-color: $color-premium;
	}
}
