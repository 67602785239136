.EnvSwitcher {
	position: fixed;
	bottom: 0;
	right: 0;
	background-color: var(--color-red);
	color: #fff;
	font-size: 10px;
	padding: 10px;
	z-index: 20000;
}

.Button {
	padding: 5px;
	border: 1px dashed #fff;
	color: #fff;
	margin: 0 0 0 5px;
	background-color: var(--color-red);
	transition: color .2s, border-color .2s, background-color .2s;

	&:hover {
		color: var(--color-red);
		border-color: #fff;
		background-color: #fff;
	}
}
