.Alert {
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	color: #F2A600;
	padding: 14px 26px;
	background: rgba(242, 166, 0, 0.1);
	border-radius: 12px;

	&--danger {
		color: var(--color-error, red);
		background: rgba(var(--color-error-rgb), .1);
	}

	&--success {
		color: var(--color-active, green);
		background: rgba(var(--color-active-rgb), .1);
	}

	strong {
		font-weight: 600;
	}

	big {
		font-size: 13px;
		line-height: 16px;
	}
}
