@import './src/resources';

.Element {
	position: relative;
	padding-left: 80px;
	padding-bottom: 24px;

	@include media-breakpoint-down(md) {
		padding-left: 16px;
		padding-right: 0;
	}

	@include media-breakpoint-down(xs) {
		padding-right: 16px;
		display: block;
	}

	:global .Avatar {
		position: absolute;
		top: 16px;
		left: 16px;
		//width: 32px;
		//height: 32px;
		background-color: #C4C4C4;
		border-radius: 6px;
	}
}

.SelectedElement {
	border-color: $color-blue-2;
}

.General {
	width: 176px;
	position: relative;

	@include media-breakpoint-down(xl) {
		width: 113px;
	}

	@include media-breakpoint-down(md) {
		width: 38.5%;
		padding-left: 63px;
	}

	@include media-breakpoint-down(xs) {
		width: 100%;
		margin-bottom: 20px;
	}

	&__progress {
		position: absolute;
		bottom: 0;
		left: 0;
		max-width: 100%;

		@include media-breakpoint-down(md) {
			left: 100%;
			top: 23px;
			margin-left: 7px;
			bottom: auto;
		}

		@include media-breakpoint-down(xs) {
			top: 68px + 12px;
			left: 1px;
		}
	}

	&__id {
		font-size: 12px;
		line-height: 136%;
		color: $color;
	}
}

.Duplicate, .More {
	font-weight: 600;
	font-size: 12px;
	color: $color-blue-2;
}

.Duplicate {
	background: none;
	margin: 0 30px 0 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	line-height: inherit;
	transition: color .2s;

	&:hover {
		color: $color;
	}
}

.Buttons {
	position: absolute;
	right: 22px;
	bottom: 0;
	line-height: 24px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.Chat {
	width: 32px;
	height: 32px;
	position: absolute;
	top: 16px;
	right: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity .2s;

	&:hover {
		opacity: .7;
	}

	&__img {
		display: block;
		max-width: 100%;
		height: auto;
	}
}
