.TransportFileJustNames {
	padding: 0 22px 22px;
}

.Header {
	font-size: 14px;
	line-height: 17px;
	color: var(--color-blue-dark);
	font-weight: 600;
	margin: 0 0 16px;
	text-align: left;
	padding-left: 40px;
}

.Button {
	display: flex;
	justify-content: center;
}
