.Container {
	width: 1280px;
	max-width: 100%;
	min-height: 746px;
	padding: 32px;
	box-shadow: -10px -10px 25px rgba(112, 124, 151, 0.05), 5px 10px 30px rgba(155, 155, 190, 0.15);
	background: #fff;
	border-radius: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
