@import './src/resources';

.Passing {
	:global .PassingSignatures {
		padding: 0;
		background: none;
		max-width: 100%;
		border-radius: 0;
		box-shadow: none;
		min-height: 0;
		margin: 32px 0;
	}
}

.Container {
	padding: 64px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include media-breakpoint-down(sm) {
		padding: 64px 0 0;

		:global .PassingData {
			border-radius: 0;
		}
	}
}

.Top {
	h2 {
		color: $color-blue-dark;
		text-align: center;
		margin: 0 auto 32px;

		@include media-breakpoint-down(sm) {
			padding: 0 16px;
		}
	}
}
