@import './src/resources';

.Group {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.Buttons {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	:global {
		.button--block {
			width: 264px;
			max-width: calc(50% - 16px);

			&:only-child {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

.AddressRow {
	width: calc(50% - 8px);

	@include media-breakpoint-down(md) {
		flex-wrap: wrap;
	}


	&.postcode {
		:global .form-field {
			&:nth-child(1) {
				width: calc(30% - 8px);
			}

			&:nth-child(2) {
				width: calc(70% - 8px);
			}
		}
	}

	&:not(.postcode) {
		:global .form-field {
			&:nth-child(1) {
				width: calc(100% - 56px - 16px);
			}

			&:nth-child(2) {
				width: 56px;
			}
		}
	}

	@include media-breakpoint-down(md) {
		@include media-breakpoint-up(sm) {
			width: 100%;

			&:nth-child(1), &:nth-child(2) {
				:global .form-field {
					width: 100%;
				}
			}


			&.postcode {
				:global .form-field {
					&:nth-child(1) {
						width: 88px;
					}

					&:nth-child(2) {
						width: calc(100% - 88px - 16px);
					}
				}
			}

			&:not(.postcode) {
				:global .form-field {
					&:nth-child(1) {
						width: calc(100% - 120px - 16px);
					}

					&:nth-child(2) {
						width: 120px;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		&.postcode {
			:global .form-field {
				&:nth-child(1) {
					width: 88px;
				}

				&:nth-child(2) {
					width: calc(100% - 88px - 16px);
				}
			}
		}

		&:not(.postcode) {
			:global .form-field {
				&:nth-child(1) {
					width: calc(100% - 56px - 16px);
				}

				&:nth-child(2) {
					width: 56px;
				}
			}
		}
	}

	@include media-breakpoint-down(xs) {
		width: 100%;

		&.postcode {
			:global .form-field {
				&:nth-child(1) {
					width: 88px;
				}

				&:nth-child(2) {
					width: calc(100% - 88px - 16px);
				}
			}
		}
	}
}
