$color: #9b9bbe;
$color-gray: #73738d;
$color-blue: #2c50ee;
$color-blue-2: #012EE8;
$color-blue-secondary: #1943ef;
$color-blue-dark: #333e63;
$color-bgr: #f8f9fd;
$color-bgr-disabled: #e1e1ea;
$color-active: #00D081;
$color-input: #f8f9fd;
$color-error: #EB2A50;
$color-red: #F94341;
$color-list: #3e4958;
$color-list-border: #F5F5FB;
$color-list-border-blank: #cdcddf;
$color-checkbox: #A8A8C6;
$color-green: #00D081;
$font-primary: 'Inter', sans-serif;
$statuses-new: #00D081;
$border-color: #DDE1E8;
$color-premium: #F2A600;

$status-new: #00D081;
$status-in_way_for_load: #BB6BD9;
$status-in_way_for_unload: #24CAE7;
$status-ready_for_load: #FF9900;
$status-ready_for_unload: #FF9900;
$status-accepted: #012EE8;
$status-loaded: #B7D24B;

$breakpoints: (
		xs: 640px,
		sm: 1024px,
		md: 1440px,
		lg: 1440px,
		xl: 1880px,
) !default;

@mixin media-breakpoint-down($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (max-width: map-get($breakpoints, $breakpoint) - .02) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}

@mixin media-breakpoint-up($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (min-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}


@function breakpoint-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $breakpoints) {
	$next: breakpoint-next($name, $breakpoints);
	@return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

@mixin media-breakpoint-only($name, $breakpoints: $breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$max: breakpoint-max($name, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}

.App {
	background-color: $color-bgr;
}

.loader-wrapper {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
