.ChatMessageReadby {
	font-size: 10px;
	width: 100%;
	text-align: right;
	padding-top: 5px;
	padding-right: 16px;

	&--reverse {
		text-align: left;
		padding-right: 0;
	}
}
