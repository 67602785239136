.TransportFileForm {
	background: #FFFFFF;
	border: 1px dashed #CDCDDF;
	box-sizing: border-box;
	border-radius: 15px;
	position: relative;
}

.Docs {
	display: flex;
	flex-wrap: wrap;
}

.Uploader {
	position: relative;
	padding: 15px;
	padding-left: 62px;

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		margin-top: -16px;
		left: 16px;
		background: url(/images/uploader.svg) no-repeat;
		background-size: contain;
		width: 32px;
		height: 32px;
	}

	&__Title {
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: var(--color-blue-dark);
		margin: 0 0 3px;
	}

	&__Subtitle {
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: var(--color);
	}
}

.OtherDocsHeader {
	color: var(--color-blue-dark);
	font-size: 13px;
	padding: 10px 22px;
}



.Delete {
	overflow: visible;
	margin: 0;
	background-image: url(/images/delete-cross.svg);
	background-size: 6px auto;
}

.Edit {
	width: 16px;
	height: 16px;
	display: block;
	text-indent: -5000px;
	background: url(edit.svg) no-repeat;
	background-size: 8px auto;
	margin: 0;
	padding: 0;
	z-index: 1;
}

.Delete, .Edit {
	position: relative;
	background-color: #E1E9FE;
	border: 1px solid #fff;
	border-radius: 50%;
	background-position: center;
	transition: background-color .2s, border-color .2s;

	&:hover {
		border-color: var(--color-blue-dark);
		opacity: 1;
	}
}

.ListElement {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	grid-gap: 7px;
}
