.empty-layout {
	background: #fff;
	box-shadow: -10px -10px 25px rgba(112, 124, 151, 0.05), 5px 10px 30px rgba(155, 155, 190, 0.15);
	border-radius: 20px;
	display: flex!important;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	align-content: center;

	&-content {
		width: 100%;
		padding: 30px;
	}

	&--copy {
		max-width: 418px;
		margin: 0 auto;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		color: $color-blue-dark;

		@include media-breakpoint-down(xs) {
			font-size: 15px;
			line-height: 18px;
		}
	}

	&--add {
		width: 224px;
		max-width: 100%;
		margin: 55px auto 0;
		font-weight: 500;
		font-size: 10px;
		line-height: 136%;
		color: #FFFFFF;
		height: 48px;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: $color-blue-2;
		box-shadow: 0px 2px 20px rgba(115, 115, 141, 0.1);
		border-radius: 10px;
		transition: background-color .2s;
		border: 0;

		&:hover {
			background-color: $color-green;
			color: #fff;
		}

		&.for-cars, &.for-trailers {
			background-color: $color-green;
			color: #fff;

			&:hover {
				background: $color-blue-2;
			}
		}

		&-inner {
			display: block;
			padding: 0 23px;
			position: relative;

			&:before {
				content: '';
				background: url(/images/plus_small.svg) no-repeat;
				background-size: contain;
				width: 7px;
				height: 8px;
				position: absolute;
				top: 50%;
				margin-top: -3px;
				right: 0;
			}
		}

		&.for-cars &-inner {
			padding-left: 30px;
			padding-right: 30px;

			&:before {
				width: 16px;
				height: 16px;
				background-image: url(/images/truck_white.svg);
				margin-top: -8px;
			}
		}

		&.for-trailers &-inner {
			padding-left: 30px;
			padding-right: 30px;

			&:before {
				width: 16px;
				height: 16px;
				background-image: url(/images/trailer_white.svg);
				margin-top: -8px;
			}
		}
	}
}
