$color-blue-dark: #333e63;

.Document {
	max-width: 100px;
	margin: 0 6px 12px;
	position: relative;

	&__doc {
		width: 100%;
		margin: 0;
	}
}

.Delete {
	overflow: visible;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: 7px;
	background-image: url(/images/delete-cross.svg);
	background-size: 6px auto;
}

.Edit {
	width: 16px;
	height: 16px;
	display: block;
	text-indent: -5000px;
	background: url(edit.svg) no-repeat;
	background-size: 8px auto;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 32px;
	right: 50%;
	margin-right: 7px;
	z-index: 1;
}

.Delete, .Edit {
	background-color: #E1E9FE;
	border: 1px solid #fff;
	border-radius: 50%;
	background-position: center;
	transition: background-color .2s, border-color .2s;

	&:hover {
		border-color: $color-blue-dark;
		opacity: 1;
	}
}
