$color: #9b9bbe;
$color-gray: #73738d;
$color-blue: #2c50ee;
$color-blue-2: #012EE8;
$color-blue-secondary: #1943ef;
$color-blue-dark: #333e63;
$color-bgr: #f8f9fd;
$color-bgr-disabled: #e1e1ea;
$color-active: #00D081;
$color-input: #f8f9fd;
$color-error: #EB2A50;
$color-red: #F94341;
$color-list: #3e4958;
$color-list-border: #F5F5FB;
$color-list-border-blank: #cdcddf;
$color-checkbox: #A8A8C6;
$color-green: #00D081;
$font-primary: 'Inter', sans-serif;
$statuses-new: #00D081;
$border-color: #DDE1E8;
$color-premium: #F2A600;

$status-new: #00D081;
$status-in_way_for_load: #BB6BD9;
$status-in_way_for_unload: #24CAE7;
$status-ready_for_load: #FF9900;
$status-ready_for_unload: #FF9900;
$status-accepted: #012EE8;
$status-loaded: #B7D24B;
