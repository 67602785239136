$color: #9b9bbe;
$color-gray: #73738d;
$color-blue: #2c50ee;
$color-blue-2: #012EE8;
$color-blue-secondary: #1943ef;
$color-blue-dark: #333e63;
$color-bgr: #f8f9fd;
$color-bgr-disabled: #e1e1ea;
$color-active: #00D081;
$color-input: #f8f9fd;
$color-error: #EB2A50;
$color-red: #F94341;
$color-list: #3e4958;
$color-list-border: #F5F5FB;
$color-list-border-blank: #cdcddf;
$color-checkbox: #A8A8C6;
$color-green: #00D081;
$font-primary: 'Inter', sans-serif;
$statuses-new: #00D081;
$border-color: #DDE1E8;
$color-premium: #F2A600;

$status-new: #00D081;
$status-in_way_for_load: #BB6BD9;
$status-in_way_for_unload: #24CAE7;
$status-ready_for_load: #FF9900;
$status-ready_for_unload: #FF9900;
$status-accepted: #012EE8;
$status-loaded: #B7D24B;

$breakpoints: (
		xs: 640px,
		sm: 1024px,
		md: 1440px,
		lg: 1440px,
		xl: 1880px,
) !default;

@mixin media-breakpoint-down($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (max-width: map-get($breakpoints, $breakpoint) - .02) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}

@mixin media-breakpoint-up($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (min-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}


@function breakpoint-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $breakpoints) {
	$next: breakpoint-next($name, $breakpoints);
	@return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

@mixin media-breakpoint-only($name, $breakpoints: $breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$max: breakpoint-max($name, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}

.Confirmation {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999999999;
	background: rgba(#000,.7);
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	&__content {
		width: 432px;
		max-width: 100%;
		background: #FFFFFF;
		box-shadow: -10px -10px 25px rgba(112, 124, 151, 0.05), 5px 10px 30px rgba(155, 155, 190, 0.15);
		border-radius: 12px;
		overflow: hidden;
	}

	&__title {
		display: block;
		background: #FF9900;
		padding: 18px 22px;
		font-weight: bold;
		font-size: 18px;
		line-height: 136%;
		color: #fff;
		margin: 0 0 17px;

		&.is-danger {
			background: $color-error;
		}
	}

	&__description {
		font-size: 14px;
		line-height: 136%;
		color: #0D1C2E;
		margin: 0 0 36px;
	}

	&__btns {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;

		@include media-breakpoint-down(xs) {
			justify-content: space-around;
		}

		&--btn {
			min-width: 112px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			background: #00D081;
			border-radius: 12px;
			height: 40px;
			padding: 5px;
			font-weight: 500;
			font-size: 10px;
			line-height: 12px;
			color: #fff;
			border: 1px solid #00D081;
			transition: color .2s, border-color .2s, background-color .2s;

			&.is-danger {
				background: #fff;
				border-color: $color-error;
				color: $color-error;

				&:hover {
					border-color: $color-error;
					background: $color-error;
					color: #fff;
				}
			}

			&:hover {
				background-color: $color-blue-secondary;
				border-color: $color-blue-secondary;
				color: #fff;
			}

			&:first-child {
				border: 1px solid #9B9BBE;
				background: #fff;
				color: $color-blue-dark;
				margin-right: 16px;

				@include media-breakpoint-down(xs) {
					margin-right: 0;
				}

				&:hover {
					background-color: $color-blue-dark;
					border-color: $color-blue-dark;
					color: #fff;
				}
			}
		}
	}
}
