.HomePassings {

}

.Empty {
	min-height: 100%;
	background: #fff;
	box-shadow: 0px 2px 30px rgba(115, 115, 141, 0.1);
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.LoadingNew {
	width: 100%;
	padding: 16px;
}
