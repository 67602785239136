.list {
	display: block;

	&-element {
		background: #fff;
		border: 1px solid #fff;
		padding: 14px 16px;
		min-height: 64px;
		display: flex;
		//align-items: center;
		flex-wrap: wrap;
		margin-bottom: 16px;
		font-size: 12px;
		font-weight: normal;
		letter-spacing: normal;
		color: $color-list;
		box-shadow: -10px -10px 25px rgba(112, 124, 151, 0.05), 5px 10px 30px rgba(155, 155, 190, 0.15);
		border-radius: 20px;
		transition: border .2s;

		&:last-child {
			margin-bottom: 0;
		}

		&.blank {
			background: $color-bgr;
			border: 1px solid $color-list-border-blank;
			box-shadow: none;
		}
	}

	&-nav {
		display: flex;
		justify-content: space-between;
		margin: 0 0 32px;
	}
}
