.option-button {
	width: 24px;
	height: 24px;
	padding: 0;
	border: 0;
	overflow: hidden;
	background: url(/images/options.svg) no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
	text-indent: -5000px;
	display: block;
	transition: opacity .2s;

	&:hover {
		opacity: .7;
	}
}
