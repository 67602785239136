.passing-status {
	&--new {
		color: $status-new;
	}

	&--in_way_for_load {
		color: $status-in_way_for_load;
	}

	&--in_way_for_unload {
		color: $status-in_way_for_unload;
	}

	&--ready_for_load {
		color: $status-ready_for_load;
	}

	&--loaded {
		color: $status-loaded;
	}

	&--accepted {
		color: $status-accepted;
	}

	&--waiting_for_unload {
		color: $status-ready_for_unload;
	}

	&--unloaded {

	}

	&--rejected {

	}

	&--waiting_for_accept {

	}
}
