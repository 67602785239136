.TransportFileFormRename {
	padding: 32px 16px;
	color: var(--color-blue-dark);
	font-size: 13px;

	:global .form-field {
		padding: 0;
	}
}

.Title {
	text-align: center;
	font-size: 13px;
	margin: 0 0 16px;
}

.Row {
	display: grid;
	grid-template-columns: calc(100% - 48px) 48px;
	align-items: center;

	&--single {
		grid-template-columns: 1fr;
	}
}

.Extension {
	text-align: right;
}

.Submit {
	display: flex;
	justify-content: center;
	margin-top: 16px;
}
