.file {
	display: inline-block;
	position: relative;
	padding-top: 48px + 3px;
	text-align: center;
	min-width: 48px;
	font-size: 10px;
	line-height: 14px;
	color: $color;
	transition: color .2s;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	&:not(.is-span):hover {
		color: $color-blue-dark;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		width: 48px;
		height: 48px;
		max-width: 100%;
		margin-left: -24px;
		background: url(/images/files/file.svg) no-repeat;
		background-size: contain;
	}

	&.csv {
		&:before {
			background: url(/images/files/csv.svg) no-repeat;
			background-size: contain;
		}
	}


	&.doc, &.docx {
		&:before {
			background: url(/images/files/doc.svg) no-repeat;
			background-size: contain;
		}
	}

	&.jpg, &.jpeg {
		&:before {
			background: url(/images/files/jpg.svg) no-repeat;
			background-size: contain;
		}
	}

	&.mov {
		&:before {
			background: url(/images/files/mov.svg) no-repeat;
			background-size: contain;
		}
	}

	&.mp3 {
		&:before {
			background: url(/images/files/mp3.svg) no-repeat;
			background-size: contain;
		}
	}

	&.pdf {
		&:before {
			background: url(/images/files/pdf.svg) no-repeat;
			background-size: contain;
		}
	}

	&.png {
		&:before {
			background: url(/images/files/png.svg) no-repeat;
			background-size: contain;
		}
	}

	&.txt {
		&:before {
			background: url(/images/files/txt.svg) no-repeat;
			background-size: contain;
		}
	}

	&.xls {
		&:before {
			background: url(/images/files/xls.svg) no-repeat;
			background-size: contain;
		}
	}

	&.xml {
		&:before {
			background: url(/images/files/xml.svg) no-repeat;
			background-size: contain;
		}
	}

	&.zip {
		&:before {
			background: url(/images/files/zip.svg) no-repeat;
			background-size: contain;
		}
	}

}
