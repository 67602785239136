@import './src/resources';

.Checkbox {
	display: flex;
	align-items: center;
	margin-bottom: 18px;
}

.Label {
	font-size: 12px;
	font-weight: 500;
	color: #3e4958;
	line-height: 15px;
	display: block;
	position: relative;
	margin-left: 8px;
}

.Half {
	:global .form-field {
		&:nth-child(1) {
			width: calc(100% - 56px - 16px);
		}

		&:nth-child(2) {
			width: 56px;
		}
	}

	&.WithPostCode {
		:global .form-field {
			&:nth-child(1) {
				width: calc(30% - 8px);
			}

			&:nth-child(2) {
				width: calc(70% - 8px);
			}
		}
	}

	@include media-breakpoint-down(md) {
		@include media-breakpoint-up(sm) {
			:global .form-field {
				&:nth-child(1) {
					width: calc(100% - 120px - 16px);
				}

				&:nth-child(2) {
					width: 120px;
				}
			}

			&.WithPostCode {
				:global .form-field {
					&:nth-child(1) {
						width: 88px;
					}

					&:nth-child(2) {
						width: calc(100% - 88px - 16px);
					}
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		:global .form-field {
			&:nth-child(1) {
				width: calc(100% - 56px - 16px);
			}

			&:nth-child(2) {
				width: 56px;
			}
		}
		&.WithPostCode {
			:global .form-field {
				&:nth-child(1) {
					width: 88px;
				}

				&:nth-child(2) {
					width: calc(100% - 88px - 16px);
				}
			}
		}
	}

	@include media-breakpoint-down(xs) {
		&.WithPostCode {
			:global .form-field {
				&:nth-child(1) {
					width: 88px;
				}

				&:nth-child(2) {
					width: calc(100% - 88px - 16px);
				}
			}
		}
	}


}
