@import './src/resources';

.Container {
	width: 256px;
	padding-left: 32px + 4px;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 16px;
		margin-left: -8px;
		width: 16px;
		height: 16px;

		@include media-breakpoint-down(md) {
			left: 0;
			margin: 0;
		}
	}

	@include media-breakpoint-down(xl) {
		width: 224px;
	}

	@include media-breakpoint-down(md) {
		padding-left: 16px + 11px;
		width: 61.5%;
		padding-right: 94px;
		margin: 0;
		min-height: 72px;
	}

	@include media-breakpoint-down(xs) {
		width: 100%;
		padding-right: 0;
	}

	&.from {
		&:before {
			background: url(/images/passings/arrow-up.svg) no-repeat;
			background-size: contain;
			background-position: center;
		}
	}

	&.to {
		&:before {
			background: url(/images/passings/arrow-down.svg) no-repeat;
			background-size: contain;
			background-position: center;
		}

		@include media-breakpoint-down(xl) {
			@media (min-width: 1621px) {
				margin-left: auto;
			}
		}

		@include media-breakpoint-down(md) {
			order: 4;
			margin-top: 8px;
		}
	}

	&.vehicle {
		padding-right: 34px;

		&:before {
			background: url(/images/passings/vehicle.svg) no-repeat;
			background-size: contain;
			background-position: center;
		}

		@include media-breakpoint-down(xl) {
			margin-left: 113px;
			margin-top: 8px;
			width: 224px + 34px;

			@media (min-width: 1621px) {
				margin-left: auto;
			}
		}

		@include media-breakpoint-down(md) {
			padding-right: 0;
			margin-left: 0;
			width: 38.5%;

			@include media-breakpoint-down(xs) {
				width: 100%;
			}
		}
	}
}

.Title {
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	color: $color-blue-dark;
	margin: 0 0 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	@include media-breakpoint-down(md) {
	}
}

.Time {
	font-weight: 500;
	font-size: 12px;
	line-height: 136%;
	color: $color-blue-dark;
	margin: 0 0 2px;
}

.Address {
	font-size: 12px;
	line-height: 136%;
	color: $color;
}

.IsHidden {
	pointer-events: none;
	opacity: 0;
}

.IsFullWidth {
	width: 100%;
	padding-left: 16px + 11px;
	position: relative;
	min-height: 75px;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 16px;
		height: 16px;
		margin: 0;
	}

	@include media-breakpoint-down(md) {
		min-height: 37px;
	}
}
